import { getCookie } from "~/plugins/helpers";

export default function ({ store, req }) {
    let token = process.server ?
        getCookie('token_' + process.env.CACHE_APP_VERSION ?? '-dev', req)
        : window.localStorage.getItem('token_' + process.env.CACHE_APP_VERSION ?? '-dev');


    let user = JSON.parse(process.server ?
        getCookie('user_' + process.env.CACHE_APP_VERSION ?? '-dev', req)
        : window.localStorage.getItem('user_'+ process.env.CACHE_APP_VERSION ?? '-dev'));

    if(token != null && token !== 'null'){
        store.commit('SET_LOGIN', token  != null);
        store.commit('login/SET_TOKEN', token)
        store.commit('login/SET_USER', user)
    }
}
