//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OneStepLoginActivateCodeModal from '../components/Modals/OneStepLoginActivateCodeModal'
import LoginModal from '../components/Modals/LoginModal/LoginModal'
import RegistrationModal from '../components/Modals/RegistrationModal/RegistrationModal'
import ResetPasswordModal from '../components/Modals/ResetPasswordModal/ResetPasswordModal'
import RetrievePasswordModal from '../components/Modals/RetrievePasswordModal/RetrievePasswordModal'
import ActivateCodeModal from '../components/Modals/ActivateCodeModal/ActivateCodeModal'
import CommonLayout from './common'
import Footer from '~/components/Subdomain/Footer'
import Header from '~/components/Subdomain/Header'

export default {
  components: {
    OneStepLoginActivateCodeModal,
    LoginModal,
    RegistrationModal,
    ResetPasswordModal,
    RetrievePasswordModal,
    ActivateCodeModal,
    Footer,
    CommonLayout,
    Header,
  },
  middleware: '',
  computed: {
    place() {
      return this.$store.state.place
    },
  },
}
