//
//
//
//
//
//
//

import Loader from '~/components/Loader/Loader'
export default {
  name: 'CommonLayout',
  components: {
    Loader
  },

  computed: {
    isToastError(){
      return  this.$store.state.toastError.showError;
    },
    toastMessage(){
      return  this.$store.state.toastError.message;
    },
    isToastSuccess(){
      return  this.$store.state.toastSuccess.showSuccess;
    },
    toastSuccessMessage(){
      return  this.$store.state.toastSuccess.message;
    },
    isLoading() {
      return this.$store.state.isLoading
    }
  },

  watch: {
    isToastError(value){
      if(value == true)
      {
        this.showDangerToast(this.toastMessage)
        this.$store.commit('SET_TOAST_ERROR', {showError: false}) // return the error back to false after the toast has been shown
      }
    },
    isToastSuccess(value){
      if(value == true)
      {
        this.showSuccessToast(this.toastSuccessMessage)
        this.$store.commit('SET_TOAST_SUCCESS', {showSuccess: false}) // return the success back to false after the toast has been shown
      }
    }
  },

  mounted(){
    // this.showConfirmingAlert('done hahah', "", "mashi", (result) => {
    // })
  }
}
