var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "grid  bg-secondary-100 min-h-screen",
    staticStyle: {
      "grid-template-rows": "5rem 1fr 1rem"
    },
    attrs: {
      "dir": _vm.$dir()
    }
  }, [_vm.isLoading ? _c('Loader') : _vm._e(), _vm._v(" "), _c('header', {}, [_c('DashNavbar')], 1), _vm._v(" "), _c('main', {
    staticClass: "grid lg:grid-cols-6 gap-2 mb-4 p-2 h-full "
  }, [_vm.showExpanded ? _c('Sidebar', {
    staticClass: "h-full lg:col-span-1 rounded-lg"
  }) : _vm._e(), _vm._v(" "), _c('Nuxt', {
    staticClass: "w-full rounded-lg col-span-5 h-full",
    class: _vm.showExpanded ? '' : 'col-span-full'
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }