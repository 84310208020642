var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "app",
      "dir": _vm.$dir()
    }
  }, [_c('CommonLayout'), _vm._v(" "), _c('main', [_c('div', {
    staticClass: "mx-auto"
  }, [_c('OneStepLoginActivateCodeModal'), _vm._v(" "), _c('LoginModal'), _vm._v(" "), _c('LoginModal'), _vm._v(" "), _c('RegistrationModal'), _vm._v(" "), _c('ResetPasswordModal'), _vm._v(" "), _c('RetrievePasswordModal'), _vm._v(" "), _c('ActivateCodeModal'), _vm._v(" "), _c('Nuxt')], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }