export default {
  app: {
    loading: 'جاري التحميل...',
    loadingDescription: 'قد تأخذ العملية بضعة ثوانٍ، رجاءًا لا تغلق الصفحة.',
    ksa: 'المملكة العربية السعودية',
  },
  nav: {
    home: 'الرئيسية',
    search: 'البحث عن قاعة',
    blog: 'المدونة',
    about: 'من نحن',
    contact: 'تواصل معنا',
    login: 'الدخول',
    logout: 'تسجيل الخروج',
    offers: 'عروضنا',
    options: 'خيارات الحجز',
    services: 'خدماتنا',
    myBookings: 'حجوزاتي',
  },
  modal: {
    signUp: 'إشترك الآن',
    signIn: 'تسجيل الدخول',
    cancel: 'إالغاء',
  },
  dashboard: {
    noBookings: 'لا يوجد لديك حجوزات بعد',
    home: 'لوحة التحكم',
    bookings: 'حجوزاتي',
    myBusinessEvents: 'طلبات الفعاليات',
    history: 'السجل المالي',
    edit: 'تعديل البيانات',
    bookingsPage: {
      qosoorHall: 'قاعة ايفينتفل',
      price: 'السعر',
      deposit: 'العربون',
      guestsNumber: 'عدد الأشخاص',
      placeDetails: 'تفاصيل القاعة',
      bookingDetails: 'تفاصيل الحجز',
      bookings: 'حجوزاتي',
      calander: 'تقويمي',
      aboutPlace: 'عن القاعة',
      bookingData: {
        title: 'بيانات الحجز',
        date: 'تاريخ الحجز',
        bookingNumber: 'رقم الحجز',
        guestsNumber: 'العدد',
        bookingOptions: 'خيار الحجز',
        bookingTerms: 'شروط الحجز',
        extra: 'الإضافات المختارة',
        menCount: 'عدد الرجال',
        womenCount: 'عدد النساء',
        pressHere: 'اضغط هنا',
      },
      bookingSummary: {
        title: 'ملخص الحجز',
        cost: 'تكلفة الحجز',
        discount: 'الخصم',
        tax: 'الضريبة',
        total: 'الإجمالي',
      },
      bookingStatus: 'حالة الحجز',
      userDetails: {
        title: 'تفاصيل العميل',
        name: 'اسم صاحب الحجز',
        phone: 'رقم الهاتف',
        email: 'البريد الإلكتروني',
        country: 'الدولة',
        city: 'المدينة',
        options: 'خيارات أخرى',
      },
    },
    myBusinessEventsPage: {
      myBusinessEvents: 'طلبات الفعاليات',
      noBusinessEvents: 'لا يوجد طلبات بعد',
    },
  },
  sar: 'ريال',
  home: {
    not_detected: 'غير محدد',
  },
  booking: {
    failed: {
      expiredSession: 'انتهت مهلة الحجز. الرجاء المحاولة مرة أخرى',
    },
  },
  error: {
    title: 'خطأ!',
    missingData: 'جميع الحقول مطلوبة، الرجاء اكمال باقي الحقول.',
  },
  needAuth: {
    title: 'هذه العملية تحتاج لتسجيل الدخول',
    description: 'هل تود القيام بتسجيل الدخول؟',
  },
  completeBooking: {
    title: 'تم الحجز بنجاح',
    text: 'هل تريد الانتقال الى صفحة حجوزاتي، لمتابعة حجزك؟',
    confirmButtonText: 'الانتقال لحجوزاتي',
  },
  vendorRequests: {
    joinUs: 'انضم لنا',

    vendorRequestsTitle: 'شريك نجاحنا، ننتظرك',
    vendorRequestsSubTitle: 'سجل معنا بخطوات بسيطة',

    forInquiries: 'عندكم اسئلة؟ تواصلوا معنا',
    formWizard: {
      next: 'التالي',
      back: 'السابق',
      finish: 'إرسال',
      loginFirst: 'الرجاء قم بتسجيل الدخول أولًا',
    },

    intro: 'انضم إلى إيفينتفل',
    vendorDetails: 'معلومات البزنس',
    personalInformation: 'بيانات التواصل',
    businessName: 'اسم البزنس',
    businessAddress: 'العنوان',
    businessAddressExample: 'مثال: الرياض - حي الملقا',
    twitter: 'حساب تويتر',
    instagram: 'حساب الانستغرام',
    website: 'الموقع الالكتروني',

    // CONTACT INFORMATION
    applicantName: 'اسم مقدم الطلب',
    applicantRole: 'الوظيفة',
    applicantPhoneNumber: 'رقم الجوال',
    applicantEmail: 'البريد الإلكتروني',
    businessOwnerName: 'اسم صاحب البزنس',
    businessOwnerPhoneNumber: 'رقم الجوال',
    businessOwnerEmail: 'البريد الإلكتروني',

    thankyou1: 'شكرًا لاختياركم إيفينتفل',
    thankyou2: 'الآن بنراجع طلبكم وبنتواصل معكم في أقرب فرصة',

    mainPage: 'الصفحة الرئيسية',
  },
  businessEvents: {
    businessEvents: 'مناسبات الشركات',
    formWizard: {
      next: 'التالي',
      back: 'السابق',
      finish: 'إرسال',
      loginFirst: 'الرجاء قم بتسجيل الدخول أولًا',
    },
    // FORM WIZARD TITLES
    eventPackage: 'المناسبة',
    personalInformation: 'بيانات التواصل',
    eventDetails: 'تفاصيل المناسبة',
    intro: 'مناسبات الشركات',
    // BUSINESS PACKAGE TAB
    package: 'الحزمة',
    choosePackage: 'ايش نوع المناسبة؟',
    chooseOption: 'ايش تصنيفها؟',
    chooseBookingOption:
      'اختر الباقة الأنسب لكم ثم اضغط التالي (تقدر تشوف تفاصيلها و المرفقات معها)',
    bookingOptionDetailedDetails: 'تفاصيل خيار المناسبة',

    option: 'المناسبة',
    // PERSONAL INFORMATION TAB
    customerName: 'الاسم',
    customerPhoneNumber: 'رقم الجوال',
    customerEmail: 'البريد الإلكتروني',
    customerCompany: 'الشركة',
    customerPosition: 'المنصب',
    // EVENT DETAILS
    eventName: 'ايش مسمى الفعالية',
    eventNameName: 'مسمى الفعالية',
    eventDate: 'تاريخ الفعالية',
    dateIsDefined: 'التاريخ محدد',
    rangeDate: 'المناسبة بتكون بأحد الأيام بين هالتاريخين',
    expectedStartDate: 'من تاريخ',
    expectedEndDate: 'إلى تاريخ',
    dateIsNotDefined: 'مو متأكدين من التاريخ بالضبط',
    isSpecificBudget: 'الميزانية محددة',
    isNotSpecificBudget: 'مو متأكدين من الميزانية',
    isSpecificGeustsCount: 'عدد الضيوف محدد',
    isNotSpecificGeustsCount: 'مو متأكدين من عدد الضيوف',
    eventCity: 'المدينة',
    eventProvince: 'المنطقة',
    budget: 'كم ميزانية الفعالية',
    budgetName: 'الميزانية',
    budgetNote:
      'ما راح نشاركها مع أحد، بس عشان نرسل طلبك لمزودي الخدمة المناسبين',
    budgetSpecifiedPlaceholder: 'مثال: 80000',
    budgetNotSpecifiedPlaceholder: 'مثال: ما بين 80000 و 100000',
    guestsCountSpecifiedPlaceholder: 'مثال: 650',
    guestsCountNotSpecifiedPlaceholder: 'مثال: ما بين 470 و 800 شخص',
    guestsCount: 'عدد الضيوف المتوقع',
    eventDescription: 'حكينا عن الفعالية',
    eventDescriptionName: 'تفاصيل الفعالية',
    eventDescriptionPlaceholder:
      'أوصفلنا الفعالية بشكل مختصر والأشياء اللي تحتاجونها عشان نتواصل مع مقدمي الخدمات المناسبين لفعاليتكم',
    eventLocationDescription: 'وصف المكان',
    eventLocationDescriptionPlaceholder:
      'مثال: سطح مكتب في حي التعاون، يوجد مساحة داخلية ومساحة خارجية.',
    eventLocation: 'مكان الفعالية',
    attachments: 'عندك مرفقات عن الفعالية تهمنا؟',
    attachmentsName: 'المرفقات',
    servicesDetailsName: 'تفاصيل الخدمات',
    servicesDetails: 'حكينا عن الخدمات الي تحتاجها، بتفاصيل قد ما تقدر',
    servicesDetailsPlaceholder: 'مثل: مقاسات، أعداد، أنواع، جودة، الخ',
    services: 'الخدمات اللي تحتاجونها',
    servicesName: 'الخدمات',

    actions: 'الإجراءات',
    editBusinessEvent: 'تعديل طلب الفعالية',
    showPlanner: 'استعراض الاقتراحات',
    attachment: 'المرفق',

    forInquiries: 'عندكم اسئلة؟ تواصلوا معنا',

    requestEventTitle: 'اطلب مناسبة لشركتكم، بخطوتين فقط',
    requestEventSubTitle:
      'من خلال خدمة مناسبات الأعمال من ايفينتفل، تقدر تطلب مناسبة لشركتكم بخطوتين',
    requestEventLi1: '1) قدم طلبك',
    requestEventLi2: ' 2) استقبل العروض',
    startNow: 'إبدأ الآن',

    thankyou1:
      'شكراً على تقديمكم لطلب مناسبتكم من خلال ايفينتفل | مناسبات الأعمال',
    thankyou2:
      'الآن بنراجع طلبكم وبنتواصل معكم اذا احتجنا تفاصيل إضافية، وبعدها بنعتمد طلبك و نرسله لمقدمين الخدمات المناسبين لكم',

    mainPage: 'الصفحة الرئيسية',
    myRequests: 'استعراض طلباتي',
  },
  edit: 'تعديل',
}
