export default function ({ route, redirect }) {
  // if (!route.path.startsWith('/saas/qosoor')) {
  //   const newPath = `/saas/qosoor${route.fullPath}`
  //   return redirect(newPath)
  // }

  console.log('===================== SAAS Middleware =====================')
  console.log(route.fullPath)
  console.log(route.path)

  // return the path as is
  return route.fullPath
}
