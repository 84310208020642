//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex'
import DashNavbar from '~/components/Dashboard/DashNavbar/DashNavbar'
import Sidebar from '~/components/Dashboard/Sidebar/Sidebar'
import Loader from '~/components/Loader/Loader'

export default {
  components: {
    Loader,
    DashNavbar,
    Sidebar,
  },
  methods: {
    ...mapMutations({
      toggleSidebar: 'sidebar/toggleSidebar',
    }),
  },
  computed: {
    showSidebar() {
      return this.$store.state.sidebar.isOpen
    },
    showExpanded() {
      return this.$store.state.sidebar.isExpanded
    },
    isLoading(){
      return  this.$store.state.isLoading;
    }
  },

  mounted() {
    if (
      window &&
      window.location.protocol === 'http:' &&
      window.location.hostname !== 'localhost'
    ) {
      window.location.protocol = 'https:'
    }
  },
}
