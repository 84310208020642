import { getCookie } from "~/plugins/helpers";

export default function ({ store, req }) {


  let query = process.server ? JSON.parse(getCookie('query_' + process.env.CACHE_APP_VERSION ?? '-dev', req)) : null;

  if(query){
    store.commit('booking/SET_SEARCH_QUERY', query)
    store.commit('searchBox/SET_SEARCH_QUERY', query)
  }
}
