//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OneStepLoginActivateCodeModal from '../components/Modals/OneStepLoginActivateCodeModal'
import LoginModal from '../components/Modals/LoginModal/LoginModal'
import RegistrationModal from '../components/Modals/RegistrationModal/RegistrationModal'
import ResetPasswordModal from '../components/Modals/ResetPasswordModal/ResetPasswordModal'
import RetrievePasswordModal from '../components/Modals/RetrievePasswordModal/RetrievePasswordModal'
import ActivateCodeModal from '../components/Modals/ActivateCodeModal/ActivateCodeModal'
import Navbar from '~/components/Navbar'
import Footer from '~/components/Footer.vue'
import CommonLayout from './common.vue'

export default {
  components: {
    Navbar,
    OneStepLoginActivateCodeModal,
    LoginModal,
    RegistrationModal,
    ResetPasswordModal,
    RetrievePasswordModal,
    ActivateCodeModal,
    Footer,
    CommonLayout
  },
  computed: {
  },
}
