import { getCookie } from "~/plugins/helpers";

export default function ({ res, next}) {
  // res.set({
  //   'X-Frame-Options', 'ALLOW-FROM https://www.messenger.com/',
  //   'X-Frame-Options', 'ALLOW-FROM https://www.facebook.com/'
  // });

  // Or it's also aliased as
  // res.header('X-Frame-Options', 'ALLOW-FROM https://www.messenger.com/')
  // res.header('X-Frame-Options', 'ALLOW-FROM https://www.facebook.com/')

  // Or using append if you already have headers set
  res.append('X-Frame-Options', 'ALLOW-FROM http://localhost:3000/')
  res.append('X-Frame-Options', 'ALLOW-FROM http://localhost:3000')

  next()
}
